<template>
  <div class="cln" :class="{ 'mobile': !isDesktop, 'premium': user && user.group !== 1 }">
    <Mobile v-if="!isDesktop" />
    <!-- <MovieAds v-if="(!user || user.group === 1)" />
    <div v-if="isDesktop" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2270065-4"></div>
      </div>
    </div> -->

    <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-3631305-1" class="rsya-block"></div>
      <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link>
    </template>

    <!-- <div class="rsya-z" /> -->

    <p class="ad">
      А еще у нас много классных аниме на русском, заходи смотри
      <a href="https://anime777.ru" target="_blank">ANIME777</a>
      бесплатно в HD.
    </p>
    
    <nuxt />


    <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-3631305-1-2" class="rsya-block"></div>
      <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link>
    </template>

    <!-- <div id="yandex_rtb_R-A-2270065-6"></div> -->
    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue'),
    // MovieAds: () => import('~/components/MovieAds.vue'),
  },
  watch: {
    changeRoute(val) {
      // console.log('val', val)
//       if (!this.isDesktop && (!this.user || this.user.group === 1)) {
//         if (this.showFS) return
//         const rsyaMobileFS = document.createElement('script')
//         rsyaMobileFS.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//     type: 'fullscreen',
//     blockId: 'R-A-2270065-3'
//   })
// })`
//         document.body.appendChild(rsyaMobileFS)
//         this.showFS = true
//       }
    },
  },
  computed: {
    ...mapState(['error', 'user', 'isDesktop']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      showFS: false,
      showVideoAd: true,
      showAdBlock: false,
    }
  },
  mounted() {
    this.showAds()

    window.addEventListener('scroll', this.lazyLoadImgs)
    this.lazyLoadImgs()
  },
  methods: {
    showAds() {
      if (!this.user || this.user.group === 1) {
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-3631305-1',
      blockId: 'R-A-3631305-1'
    })
    })`
          document.body.appendChild(rsyaHorizon)

          const rsyaHorizon2 = document.createElement('script')
          rsyaHorizon2.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-3631305-1-2',
      pageNumber: 2,
      blockId: 'R-A-3631305-1'
    })
    })`
          document.body.appendChild(rsyaHorizon2)

          if (!this.isDesktop) {
            const rsyaFSMobile = document.createElement('script')
            rsyaFSMobile.innerHTML = `
    window.yaContextCb.push(()=>{
      Ya.Context.AdvManager.render({
        "blockId": "R-A-3631305-4",
        "type": "fullscreen",
        "platform": "touch"
      })
    })`
            // document.body.appendChild(rsyaFSMobile)

            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
        "blockId": "R-A-3631305-2",
        "type": "floorAd",
        "platform": "touch"
    })
    })`
            document.body.appendChild(rsyaFooterMobile)
          } else {
            const rsyaFSDesktop = document.createElement('script')
            rsyaFSDesktop.innerHTML = `
    window.yaContextCb.push(()=>{
      Ya.Context.AdvManager.render({
        "blockId": "R-A-3631305-5",
        "type": "fullscreen",
        "platform": "desktop"
      })
    })`
            // document.body.appendChild(rsyaFSDesktop)

            const rsyaFooterDesktop = document.createElement('script')
            rsyaFooterDesktop.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
        "blockId": "R-A-3631305-3",
        "type": "floorAd",
        "platform": "touch"
    })
    })`
            document.body.appendChild(rsyaFooterDesktop)
          }
        }, 200)
      }
    }
  }
}
</script>

<style lang="stylus">
p.ad
  padding: 5px 10px
  color: #ddd
  background-color: rgba(255, 255, 255, 0.1)
  a
    color: orange
    &:hover
      color: red
</style>
