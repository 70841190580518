import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e6674f8 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _f0ac5fe0 = () => interopDefault(import('../pages/cp3015/index.vue' /* webpackChunkName: "pages/cp3015/index" */))
const _9a1c73de = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _17a9cd4a = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _0c700e79 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5aa6547e = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _673b7574 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _2ed6d370 = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _69fa1f26 = () => interopDefault(import('../pages/cp3015/comments.vue' /* webpackChunkName: "pages/cp3015/comments" */))
const _73ab34a1 = () => interopDefault(import('../pages/cp3015/donate.vue' /* webpackChunkName: "pages/cp3015/donate" */))
const _5ab60792 = () => interopDefault(import('../pages/cp3015/feedback.vue' /* webpackChunkName: "pages/cp3015/feedback" */))
const _87cfe75e = () => interopDefault(import('../pages/cp3015/kino.vue' /* webpackChunkName: "pages/cp3015/kino" */))
const _60e08f02 = () => interopDefault(import('../pages/donate/success.vue' /* webpackChunkName: "pages/donate/success" */))
const _6b3aaf86 = () => interopDefault(import('../pages/profile/favs.vue' /* webpackChunkName: "pages/profile/favs" */))
const _28f85b80 = () => interopDefault(import('../pages/ranobe/_id/index.vue' /* webpackChunkName: "pages/ranobe/_id/index" */))
const _465aa320 = () => interopDefault(import('../pages/ranobe/_id/chapter/_ch.vue' /* webpackChunkName: "pages/ranobe/_id/chapter/_ch" */))
const _3b57a103 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/copyright",
    component: _5e6674f8,
    name: "copyright"
  }, {
    path: "/cp3015",
    component: _f0ac5fe0,
    name: "cp3015"
  }, {
    path: "/donate",
    component: _9a1c73de,
    name: "donate"
  }, {
    path: "/popular",
    component: _17a9cd4a,
    name: "popular"
  }, {
    path: "/privacy",
    component: _0c700e79,
    name: "privacy"
  }, {
    path: "/signin",
    component: _5aa6547e,
    name: "signin"
  }, {
    path: "/success",
    component: _673b7574,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _2ed6d370,
    name: "termsofuse"
  }, {
    path: "/cp3015/comments",
    component: _69fa1f26,
    name: "cp3015-comments"
  }, {
    path: "/cp3015/donate",
    component: _73ab34a1,
    name: "cp3015-donate"
  }, {
    path: "/cp3015/feedback",
    component: _5ab60792,
    name: "cp3015-feedback"
  }, {
    path: "/cp3015/kino",
    component: _87cfe75e,
    name: "cp3015-kino"
  }, {
    path: "/donate/success",
    component: _60e08f02,
    name: "donate-success"
  }, {
    path: "/profile/favs",
    component: _6b3aaf86,
    name: "profile-favs"
  }, {
    path: "/ranobe/:id",
    component: _28f85b80,
    name: "ranobe-id"
  }, {
    path: "/ranobe/:id?/chapter/:ch?",
    component: _465aa320,
    name: "ranobe-id-chapter-ch"
  }, {
    path: "/",
    component: _3b57a103,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
